/* eslint-disable react/function-component-definition*/
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import styles from '../../pages/styles/techPages.module.scss';
import built from '../../images/technology/vsm/built-for-video-operations-teams.jpg';
import alerts from '../../images/technology/vsm/get-real-time-alerts.jpg';
import identify from '../../images/technology/vsm/identify-the-issue-immediately.png';
import cut from '../../images/technology/vsm/cut-your-response-time-in-half.jpg';

export default function MobileTabCardsVSM({ id }) {
    const { t } = useTranslation();
    const [ cardData, setCardData ] = useState(0);

    const cardsContent = [
        {
            id: 0,
            image: built,
            title: t('siteMetadata.videoServiceMonitoring.built'),
            text: t('siteMetadata.videoServiceMonitoring.builtText'),
            alt: 'Built for Video Operations teams'
        },
        {
            id: 1,
            image: alerts,
            title: t('siteMetadata.videoServiceMonitoring.get'),
            text: t('siteMetadata.videoServiceMonitoring.getText'),
            alt: 'Get real-time alerts'
        },
        {
            id: 2,
            image: identify,
            title: t('siteMetadata.videoServiceMonitoring.identify'),
            text: t('siteMetadata.videoServiceMonitoring.identifyText'),
            alt: 'Identify the issue immediately'
        },
        {
            id: 3,
            image: cut,
            title: t('siteMetadata.videoServiceMonitoring.cut'),
            text: t('siteMetadata.videoServiceMonitoring.cutText'),
            alt: 'Cut your response time in half'
        }
    ];

    const handleClick = (name, e) => {
        setCardData(name);
    };

    return (
        <section id={styles.interactiveSection}>
            <h2 id={styles.interactiveHeaderQa}>
                {t('siteMetadata.videoServiceMonitoring.leave')}
            </h2>
            <div className={styles.interactiveSectionWrapper}>
                <ul id={styles.interactiveList}>
                    {
                        cardsContent.map(card => (
                            <li
                                key={card.id}
                                className={styles.interactiveItem}
                                onClick={(e) => handleClick(card.id, e)}
                            >
                                <div className={styles.chevronButton}>
                                    <FontAwesomeIcon
                                        icon={cardData === card.id ? faChevronDown : faChevronRight}
                                    />
                                </div>
                                <div className={styles.cardText}>
                                    <h3 className={styles.cardTitle}>
                                        {card.title}
                                    </h3>
                                    <p className={styles.cardParagraph}>
                                        <p className={cardData === card.id ? styles.cardVisible : styles.cardInvisible}>
                                            {card.text}
                                        </p>
                                    </p>
                                </div>
                                <div className={styles.interactiveImage}>
                                    <img src={card.image} className={cardData === card.id ? styles.imageVisible : styles.imageInvisible} alt={card.alt} />
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </section>
    );
}